.title {
  font-size: 100px;
  font-family: 'Dancing Script', cursive;
}
.subtitle {
  font-size: 25px;
  padding-top: 10px;
}
.intro-text {
  width: 100%;
  text-align: center;
}
.main {
  min-height: 93vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../../image.png');
  background-position: center;
  background-size: cover;
}
.buttonContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

.landingbutton {
  width: 200px;
  height: 55px;
}

@media only screen and (min-width: 1200px) {
  .buttonContainer {
    padding: 0 200px;
  }
  .title {
    padding: 0 80px;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 80px;
  }
  .landingbutton {
    width: 150px;
    height: 50px;
  }
}
